import './App.css';
import CheckIn from "./Components/CheckIn";
import {SnackbarProvider} from 'notistack';

export default function App() {
  return (
      <SnackbarProvider maxSnack={3}>
        <img src="logo.png" alt="Logo" style={{
          width: "10rem",
          height: "auto",
          position: "absolute",
          zIndex: "9999"
        }}/>
        <div className="App">
          <CheckIn/>
        </div>
      </SnackbarProvider>
  );
}
