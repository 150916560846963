import {useState} from "react";
import {animated, config, useSpring} from '@react-spring/web'
import {useDrag} from 'react-use-gesture'
import {Button, Grid, InputLabel, Typography} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
  isValidPhoneNumber,
} from 'libphonenumber-js'
import MuiPhoneNumber from "material-ui-phone-number";
import DayjsUtils from "@date-io/dayjs";
import axios from 'axios';
import {useSnackbar} from "notistack";

export default function CheckIn() {

  const {enqueueSnackbar} = useSnackbar();

  const [{x, y}, api] = useSpring(
      () => ({x: 0, y: 0, config: {...config.gentle}}))

  const [phoneNumber, setPhoneNumber] = useState("");
  const [dob, handleDobChange] = useState(null);

  const [submitted, setSubmitted] = useState(false);

  const [status, setStatus] = useState("Checking in..");
  const [preStatus, setPreStatus] = useState("Continue swiping");

  const [nextActionUrl, setNextActionUrl] = useState(null);

  const handlePhoneNumber = (value) => {
    setPhoneNumber(value);
  };

  const urlParams = window.location.search;

  const checkInInit = () => {

    const siteId = new URLSearchParams(urlParams).get("site")

    if (!siteId) {
      setPreStatus("Invalid URL. Contact your provider.")
      enqueueSnackbar("Could not verify URL. Contact your provider", {
        variant: 'error',
      })
      setTimeout(() => {
        api.start({x: 0, y: 0})
      }, 100)
      return;
    }

    //validate
    if (isValidPhoneNumber(phoneNumber)) {

      setPreStatus("Keep swiping")
      api.start({x: 0, y: -900})
      setSubmitted(true);

      //phoneNumber:phoneNumber,
      //dob:dob.toDateString(),
      //facilityPhone:""

      // console.log(dob.toLocaleString());

      // console.log(dob.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));
      axios.post(
          // "http://localhost:5001/hometestbox-aad36/us-central1/checkIn",
          "https://us-central1-hometestbox-aad36.cloudfunctions.net/checkIn",
          {
            "phoneNumber": phoneNumber,
            "dob": new Date(dob).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              timeZone: "UTC"
            }),
            "facilityId": new URLSearchParams(urlParams).get("site")
          },
      ).then((res) => {
        setStatus(res.data.message);
      }).catch((err) => {

        console.log(err.response)

        if (err.response.data?.redirectUrl) {
          setStatus(err.response.data.message);
          setNextActionUrl(err.response.data?.redirectUrl)
          // enqueueSnackbar("Redirecting. Please wait..",{
          //     variant:"warning"
          // });
          // setTimeout(()=>{
          //     window.location.href = `${err.response.data.redirectUrl}`
          // },2000);
        } else {
          setStatus(err.response.data.message);
          enqueueSnackbar(err.response.data.message, {
            variant: 'error',
          })
        }

        // api.start({x:0,y:0})
        // setSubmitted(true);
      })
    } else {
      setPreStatus("Invalid phone number. Try again.")
      enqueueSnackbar("Invalid phone number. Try again.", {
        variant: 'error',
      })
      setTimeout(() => {
        api.start({x: 0, y: 0})
      }, 100)
      return;
    }

  }

  const bind = useDrag(({down, movement: [mx, my],}) => {

    api.start({x: 0, y: my})

    if (my < -200 && !down) {
      checkInInit()

    } else {
      if (!down) {
        api.start({x: 0, y: 0})

      }

    }

  }, {useTouch: true, pointer: {buttons: [1]}, preventDefault: true,})

  // const bind = useDrag(({ down, movement: [y], args: [index] }) => api.start(i => i === index && { y: down ? y : 0 }), {
  //     axis: 'y',
  // })

  const checkIn = (e, trigger) => {

    // console.log("x")

    api.start({x: 0, y: -1000})
    checkInInit();

    // transform
  }

  return (
      <div style={{height: "-webkit-fill-available"}} onClick={() => {
        if (nextActionUrl) {
          window.location.href = nextActionUrl
        }

      }}>
        <animated.div  {...bind()} style={{
          x,
          y,
          backgroundColor: "#fefffd",
          touchAction: "pan-up"
        }} className="fillScreen">

          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Grid container justify="center" alignContent="center">

              <Grid item sm={12} xs={12}
                    style={{marginTop: "100px", padding: "0 20px 0 20px"}}>
                <Typography gutterBottom variant="h1"
                            style={{fontSize: "3rem", bottomPadding: "20px"}}>
                  Check In
                </Typography>
                <InputLabel>Use the phone number and the birthdate you used to
                  register.</InputLabel>


              </Grid>

              <Grid item lg={9} sm={8} xs={8} style={{paddingTop: "2rem"}}>
                <MuiPhoneNumber style={{width: "100%"}} label="Phone Number"
                                defaultCountry={'us'} disableAreaCodes
                                onChange={handlePhoneNumber}/>
              </Grid>


              <Grid item lg={9} sm={6} xs={6} style={{marginTop: "50px"}}>
                <KeyboardDatePicker
                    style={{width: "100%"}}
                    disableFuture
                    autoOk
                    clearable
                    value={dob}
                    onChange={date => handleDobChange(date)}
                    label="Date of birth"
                    maxDateMessage={"Please select a valid date for the Date of Birth."}
                    placeholder="12/1/2020"
                    format="MM/DD/YYYY"

                />

              </Grid>


            </Grid>
          </MuiPickersUtilsProvider>
          <Grid container justify={"center"}>
            <Grid item lg={6} xs={8}>
              <Button fullWidth variant={"contained"} color={"primary"} style={{
                marginTop: "2rem",
                marginBottom: "1rem",
                textAlign: "center"
              }} onClick={checkIn}>
                SWIPE UP OR CLICK TO CHECK IN
              </Button>
            </Grid>
          </Grid>


          {/*<FingerprintIcon  style={{fontSize:50, position:"relative",top:"210px",bottom:"80px"}} />*/}
          <Typography style={{marginTop: "5rem", marginBottom: "1rem"}}>Need to
            register instead?</Typography>
          <Button target={"_blank"}
                  href={`https://register.labport.app/?site=${new URLSearchParams(
                      urlParams).get("site")}`} color={"primary"}
                  variant={"outlined"}>Sign Up</Button>


        </animated.div>
        <div style={{
          zIndex: "-2",
          position: "absolute",
          bottom: "10px",
          fontSize: "1rem",
          left: "0",
          right: "0"
        }}>
          <Typography variant="h1" style={{fontSize: "1rem"}}>
            {preStatus}
          </Typography>
        </div>
        <div style={{
          zIndex: "-3",
          position: "absolute",
          bottom: "10px",
          fontSize: "1rem",
          left: "0",
          right: "0",
          backgroundColor: "#282828",
          padding: "250px 0 250px 0",
          height: "-webkit-fill-available"
        }}>
          <img style={{width: "240px"}} src={"logo.png"}/>

          <Typography variant="h1"
                      style={{fontSize: "1rem", color: "#fff", margin: "1rem"}}>
            {status}
          </Typography>
          {nextActionUrl && <Button

              style={{
                position: "relative",
                zIndex: "2",
              }}
              target={"_blank"} variant={"contained"} color={"primary"}
              href={nextActionUrl}
          >Proceed</Button>}
        </div>


      </div>
  )
}
